import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    "home": "Home",
                    "events": "Events",
                    "schedule": "Schedule",
                    "faq": "FAQ",
                    "coming_soon": "Coming Soon!",
                    "hero": "The 1st Harley-Davidson® official event in",

                    "morning": "MORNING",
                    "afternoon": "AFTERNOON",
                    "evening": "EVENING",

                    "ck1": "Join the ",
                    "ck2": "competition and become",
                    "ck3": "the King of the Kings.",

                    "required": "Please fill the required forms",

                    "name-label": "Name",
                    "name-placeholder": "What should we call you?",

                    "name-ck-label": "Participant Name (customer / dealer / custom builder)",
                    "name-ck-placeholder": "Please enter the name",

                    "country-label": "Country",
                    "country-placeholder": "Please enter your country",

                    "phone-label": "Phone Number",
                    "phone-placeholder": "Please enter your phone number",

                    "phone-label": "Phone Number",
                    "phone-placeholder": "Please enter your phone number",

                    "email-label": "Email Address",
                    "email-placeholder": "Please enter your email address",

                    "bundle-label": "Select Bundle",
                    "bundle-placeholder": "Please select a bundle",

                    "community-label": "Select Community",
                    "community-placeholder": "Please select your community",

                    "dealership-label": "Associated Dealership",
                    "dealership-placeholder": "Please enter your associated dealership",

                    "bike-label": "Bike Nickname",
                    "bike-placeholder": "What should we call your bike?",

                    "donor-label": "Donor Model",
                    "donor-placeholder": "Please enter your donor model",

                    "pics-label": "Pictures from all 4 angles (specs)",
                    "pics-placeholder": "Please upload 4 pictures of your bike from all 4 angles",

                    "video-label": "Video (optional)",
                    "video-placeholder": "You can upload a video of your bike here (optional)",

                    "rideable-label": "Is your bike rideable?",

                    "mileage-label": "Current Mileage",
                    "mileage-placeholder": "Please enter your current mileage",

                    "genuine-label": "Does your bike use at least 50% of genuine Harley Davidson parts?",

                    "amount-label": "Amount to pay",
                    "payment-channel": "Payment Channel",
                    "account-number": "Account Number",
                    "payment-label": "Please upload your proof of payment here",

                    "success-form": "Your proof of payment has been successfully sent. Our administrator will check it in 1x24 hours, and will contact you for confirmation. Thank you!",

                    "failed-form": "Sorry, there seems to be something wrong when submitting your proof of payment. Please try again later",

                    "payment-instructions": {
                        "title": "How to pay",
                        "bank1": [
                            "Transfer the amount to the account number provided.",
                            "Use the reference code mentioned in the confirmation email.",
                            "Ensure to complete the payment before the deadline."
                        ],
                        "bank2": [
                            "Deposit the amount at any branch of Bank 2.",
                            "Write down your order number on the deposit slip.",
                            "Submit the deposit slip at the counter."
                        ],
                        "bank3": [
                            "Pay via Bank 3’s online banking platform.",
                            "Follow the steps outlined in the online banking portal.",
                            "Attach a screenshot of the transaction in your confirmation email."
                        ]
                    },

                    "faq1": "You can always email us at ",
                    "faq2": "for further information",

                    "faqs": [
                        {
                            question: "How do I register?",
                            answer: "You can register for the event by visiting our registration page on the website. Fill out the required information and follow the instructions to complete your registration."
                        },
                        {
                            question: "Where can I purchase tickets?",
                            answer: "Tickets can be purchased on our website or at the event entrance, subject to availability."
                        },
                        {
                            question: "What can I bring?",
                            answer: "You can bring personal items such as water bottles, snacks, and cameras. Please check the event guidelines on our website for a detailed list of permitted and prohibited items."
                        }
                    ]
                }
            },
            id: {
                translation: {
                    "home": "Home",
                    "events": "Events",
                    "schedule": "Schedule",
                    "faq": "FAQ",
                    "coming_soon": "Segera Hadir!",
                    "hero": "Event official Harley Davidson® pertama di",

                    "morning": "PAGI",
                    "afternoon": "SIANG",
                    "evening": "SORE",

                    "ck1": "Ikuti kompetisi ",
                    "ck2": "dan jadilah",
                    "ck3": "the King of the Kings.",

                    "required": "Harus diisi",

                    "phone-label": "Nomor Handphone",
                    "phone-placeholder": "Silakan masukkan nomor handphone Anda",

                    "name-label": "Nama",
                    "name-placeholder": "Silakan masukkan nama Anda",

                    "name-ck-label": "Nama Partisipan (customer / dealer / custom builder)",
                    "name-ck-placeholder": "Silakan masukkan nama",

                    "country-label": "Negara",
                    "country-placeholder": "Silakan masukkan negara Anda",

                    "email-label": "Alamat Email",
                    "email-placeholder": "Silakan masukkan alamat email Anda",

                    "bundle-label": "Pilih Bundle",
                    "bundle-placeholder": "Silakan pilih paket yang Anda inginkan",

                    "community-label": "Pilih Komunitas",
                    "community-placeholder": "Silakan pilih komunitas asal Anda",

                    "dealership-label": "Dealer Terkait",
                    "dealership-placeholder": "Silakan masukkan dealer terkait Anda",

                    "bike-label": "Nama Panggilan Motor",
                    "bike-placeholder": "Apa nama panggilan motor Anda?",

                    "donor-label": "Model Donor",
                    "donor-placeholder": "Silakan masukkan model donor Anda",

                    "pics-label": "Gambar dari 4 Sudut (specs)",
                    "pics-placeholder": "Silakan unggah 4 gambar motor Anda dari 4 sudut berbeda",

                    "video-label": "Video (opsional)",
                    "video-placeholder": "Anda dapat mengunggah video motor Anda di sini (opsional)",

                    "rideable-label": "Apakah motor Anda dapat dikendarai?",

                    "mileage-label": "Kilometer Saat Ini",
                    "mileage-placeholder": "Silakan masukkan kilometer saat ini",

                    "genuine-label": "Apakah motor Anda menggunakan setidaknya 50% suku cadang asli Harley Davidson?",

                    "amount-label": "Jumlah yang perlu dibayar",
                    "payment-channel": "Methode Pembayaran",
                    "account-number": "Nomor Rekening",
                    "payment-label": "Silakan upload bukti pembayaran Anda disini",

                    "success-form": "Bukti pembayaran Anda berhasil dikirim. Petugas kami akan memeriksa dalam 1x24 jam dan menghubungi Anda untuk konfirmasi. Terima kasih!",

                    "failed-form": "Maaf, terjadi kesalahan saat mengirim bukti pembayaran. Silakan coba lagi nanti.",

                    "payment-instructions": {
                        "title": "Cara melakukan pembayaran",
                        "bank1": [
                            "Transfer jumlah yang tertera ke nomor rekening yang disediakan.",
                            "Gunakan kode referensi yang disebutkan dalam email konfirmasi.",
                            "Pastikan untuk menyelesaikan pembayaran sebelum tenggat waktu."
                        ],
                        "bank2": [
                            "Setor jumlah tersebut di cabang Bank 2 manapun.",
                            "Tuliskan nomor pesanan Anda pada slip setoran.",
                            "Serahkan slip setoran di loket."
                        ],
                        "bank3": [
                            "Bayar melalui platform perbankan online Bank 3.",
                            "Ikuti langkah-langkah yang dijelaskan di portal perbankan online.",
                            "Lampirkan tangkapan layar transaksi di email konfirmasi Anda."
                        ]
                    },

                    "faq1": "Anda dapat mengontak kami di ",
                    "faq2": "untuk informasi lebih lanjut.",

                    "faqs": [
                        {
                            question: "Bagaimana cara mendaftar?",
                            answer: "Anda dapat mendaftar untuk acara tersebut dengan mengunjungi halaman pendaftaran di situs web kami. Isi informasi yang diperlukan dan ikuti petunjuk untuk menyelesaikan pendaftaran Anda."
                        },
                        {
                            question: "Di mana saya bisa membeli tiket?",
                            answer: "Tiket dapat dibeli di situs web kami atau di pintu masuk acara, tergantung ketersediaan."
                        },
                        {
                            question: "Apa yang bisa saya bawa?",
                            answer: "Anda dapat membawa barang pribadi seperti botol air, makanan ringan, dan kamera. Silakan periksa pedoman acara di situs web kami untuk daftar rinci barang yang diizinkan dan dilarang."
                        }
                    ]
                }
            }
        },
        lng: 'id',
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;
