import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../lib/LanguageContext';
import RegisterButton from './RegisterButton';

const Navbar = () => {
    const { t } = useTranslation();
    const { language, switchLanguage } = useLanguage();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [activeSection, setActiveSection] = useState('home');
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const mobileMenuRef = useRef(null);

    const toggleMobileMenu = () => setMobileMenuOpen(prev => !prev);

    const handleClickOutside = (event) => {
        if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
            setMobileMenuOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener to handle clicks outside the menu
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section');
            let currentSection = '';
            sections.forEach(section => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.clientHeight;
                if (window.scrollY >= sectionTop - sectionHeight / 3 && window.scrollY < sectionTop + sectionHeight - sectionHeight / 3) {
                    currentSection = section.getAttribute('id');
                }
            });
            setActiveSection(currentSection);
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initialize active section on load

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const isActive = (section) => activeSection === section ? 'text-white border-b-2 border-[#FE9300]' : 'text-gray-400 hover:text-gray-300';

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const availableLanguages = [
        { code: 'en', label: 'English', flag: '/united-kingdom.png' },
        { code: 'id', label: 'Indonesian', flag: '/indonesia.png' }
    ];

    return (
        <>
            <nav className={`fixed top-0 right-0 opacity-100 md:rounded-none w-full bg-black text-white py-4 md:py-2 px-4 md:px-8 flex justify-between items-center z-50 text-sm md:text-lg`}>
                <div className="flex items-center md:mb-0 space-x-2 mt-2 md:mt-0 md:space-x-4">
                    <a href="#home" className={`hover:text-gray-300 ${isActive('home')}`}>Home</a>
                    {/* <a href="#custom-kings" className={`hover:text-gray-300 ${isActive('custom-kings')}`}>Custom Kings</a> */}
                    <div className="relative inline-block text-left">
                        {/* Custom Kings link with dropdown toggle */}
                        <a
                            href="#custom-kings"
                            onClick={(e) => {
                                e.preventDefault(); // Prevent default navigation
                                setIsDropdownOpen(!isDropdownOpen);
                            }}
                            className={`hover:text-gray-300 ${isActive('custom-kings')}`}
                        >
                            Custom Kings
                        </a>

                        {/* Dropdown menu */}
                        {isDropdownOpen && (
                            <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-black text-white ring-1 ring-black ring-opacity-5">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    <a href="#sub-item-1" className="block px-4 py-2 text-sm hover:text-gray-300">
                                        About Custom Kings (coming soon)
                                    </a>
                                    <a href="#sub-item-2" className="block px-4 py-2 text-sm hover:text-gray-300">
                                        How to Participate (coming soon)
                                    </a>
                                    <RegisterButton className="block px-4 py-2 text-sm hover:text-gray-300" label="Register" customKing={true} />
                                </div>
                            </div>
                        )}
                    </div>
                    <a href="#events" className={`hover:text-gray-300 ${isActive('events')}`}>Events</a>
                    <a href="#schedule" className={`hover:text-gray-300 ${isActive('schedule')}`}>Schedule</a>
                    <a href="#faq" className={`hover:text-gray-300 ${isActive('faq')}`}>FAQ</a>
                </div>

                <div className="flex md:items-center flex-col md:flex-row mt-2 md:mt-0 md:space-x-4">
                    <div className='hidden md:flex flex-row mt-1 gap-4'>
                        {/* <img className='w-auto h-[2.1rem]' src='/img/brands/inchcape_white.png' /> */}
                        <img className='w-auto h-[2.1rem]' src='/img/brands/jlmauto_white.png' />
                    </div>
                    <img src="/logo_navbar.png" alt="Harley Davidson Logo" className="hidden md:flex md:h-3" />
                    <button
                        onClick={toggleDropdown}
                        className="text-white px-3 md:py-2 rounded hover:bg-gray-600 flex gap-2 items-center"
                    >
                        <img
                            src={availableLanguages.find(lang => lang.code === language)?.flag}
                            alt={language}
                            className="w-4 h-4 object-cover rounded-full"
                        />
                        {language.toUpperCase()}
                    </button>
                    {dropdownOpen && (
                        <div className="absolute top-full mt-2 right-0 bg-gray-700 text-white rounded shadow-lg z-20">
                            {availableLanguages.map((lang) => (
                                <button
                                    key={lang.code}
                                    className="flex items-center gap-2 px-4 py-2 hover:bg-gray-600 w-full text-left"
                                    onClick={() => {
                                        switchLanguage(lang.code);
                                        setDropdownOpen(false);
                                    }}
                                >
                                    <img
                                        src={lang.flag}
                                        alt={lang.label}
                                        className="w-4 h-4 object-cover rounded-full"
                                    />
                                    {lang.label}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                <div className='flex flex-row items-center justify-center fixed md:hidden top-[10px] left-1/2 transform -translate-x-1/2 flex h-2 gap-2'>
                    {/* <img className='w-auto h-[1.5rem]' src='/img/brands/inchcape_white.png' /> */}
                    <img className='w-auto h-[1.5rem]' src='/img/brands/jlmauto_white.png' />
                    <img src="/logo_navbar.png" alt="Harley Davidson Logo" className="h-auto w-[8rem] mb-1" />
                </div>
                {/* Mobile Menu Button */}
                {/* <button
                    onClick={() => { setMobileMenuOpen(!mobileMenuOpen) }}
                    className="md:hidden text-white focus:outline-none fixed right-[1rem]"
                >
                    <svg className="h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button> */}
            </nav>

            {/* Mobile Menu */}
            {/* <div ref={mobileMenuRef} className={`fixed inset-0 bg-black bg-opacity-85 z-40 h-[40%] lg:hidden ${mobileMenuOpen ? 'block' : 'hidden'}`}>
                <div className="flex flex-col items-center pt-10">
                    <a href="#home" className={`text-white text-lg py-2 ${isActive('home')}`}>Home</a>
                    <a href="#events" className={`text-white text-lg py-2 ${isActive('events')}`}>Events</a>
                    <a href="#schedule" className={`text-white text-lg py-2 ${isActive('schedule')}`}>Schedule</a>
                    <a href="#faq" className={`text-white text-lg py-2 ${isActive('faq')}`}>FAQ</a>
                    <button
                        onClick={toggleDropdown}
                        className="text-white px-3 py-2 rounded mt-4 flex gap-2 items-center"
                    >
                        <img
                            src={availableLanguages.find(lang => lang.code === language)?.flag}
                            alt={language}
                            className="w-4 h-4 object-cover rounded-full"
                        />
                        {language.toUpperCase()}
                    </button>
                    {dropdownOpen && (
                        <div className="bg-gray-700 text-white rounded mt-2">
                            {availableLanguages.map((lang) => (
                                <button
                                    key={lang.code}
                                    className="flex items-center gap-2 px-4 py-2 hover:bg-gray-600 w-full text-left"
                                    onClick={() => {
                                        switchLanguage(lang.code);
                                        setDropdownOpen(false);
                                    }}
                                >
                                    <img
                                        src={lang.flag}
                                        alt={lang.label}
                                        className="w-4 h-4 object-cover rounded-full"
                                    />
                                    {lang.label}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div> */}
        </>
    );
};

export default Navbar;
