import { FaArrowUp } from "react-icons/fa";

const truncateText = (text, limit = 100) => {
    if (text.length > limit) {
        return text.substring(0, limit) + '...';
    }
    return text;
};

const Event = ({ title, description, isExpanded, onToggle }) => (

    <div className={`event-content ${isExpanded ? 'expanded' : ''}`}>
        <h3 className="text-xl font-semibold text-orange">{title}</h3>
        <p className={isExpanded ? '' : 'truncate'}>
            {isExpanded ? description : truncateText(description)}
        </p>
        <button
            onClick={onToggle}
            className={`absolute right-4 bottom-2 text-white bg-black rounded-full p-1 ${isExpanded ? 'spin' : ''}`}
        >
            <FaArrowUp />
        </button>
    </div>
);

export default Event;