import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaMoneyBill, FaPlus, FaSuitcase } from 'react-icons/fa';
import { FaPenToSquare } from 'react-icons/fa6';

const FAQ = () => {
    const { t } = useTranslation();
    const faqs = t('faqs', { returnObjects: true });

    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleFAQ = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };
    return (
        <div className='w-3/4 md:w-[50rem]'>
            {faqs.map((faq, index) => (
                <div key={index} className="w-full md:w-[50rem] text-start mb-4">
                    <div
                        className="cursor-pointer flex items-center justify-between w-full"
                        onClick={() => toggleFAQ(index)}
                    >
                        <div className="flex items-center">
                            {index === 0 ? <FaPenToSquare className="w-8 mr-2 opacity-50" /> : index === 1 ? <FaMoneyBill className="w-8 mr-2 opacity-50" /> : <FaSuitcase className="w-8 mr-2 opacity-50" />}

                            <h2 className="text-xl">{faq.question}</h2>
                        </div>
                        {expandedIndex === index ? (
                            <FaMinus className="ml-2 opacity-50" />
                        ) : (
                            <FaPlus className="ml-2 opacity-50" />
                        )}
                    </div>
                    <div
                        className={`faq-answer mt-2 ${expandedIndex === index ? 'expanded' : ''}`}
                        style={{ maxHeight: expandedIndex === index ? '200px' : '0' }}
                    >
                        <p>{faq.answer}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FAQ;
