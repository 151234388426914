import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(3); // Default to 3 items per page

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setItemsPerPage(2); // Show 2 items per page on small screens
            } else {
                setItemsPerPage(3); // Show 3 items per page on larger screens
            }
        };

        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const guests = [
        {
            id: 1, name: 'DJ Hannah X', imgSrc: '/img/guest/dj_hannah.jpg', socialLinks: {
                facebook: 'https://facebook.com',
                twitter: 'https://twitter.com',
                instagram: 'https://www.instagram.com/hannahx.music/',
                tiktok: 'https://linkedin.com'
            }, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        {
            id: 2, name: 'DJ Berlin Bintang', imgSrc: '/img/guest/dj_berlin.jpg', socialLinks: {
                facebook: 'https://www.facebook.com/djberlinbintang/',
                twitter: 'https://x.com/BerlinBintang',
                instagram: 'https://www.instagram.com/berlinbintang/',
                tiktok: 'https://www.tiktok.com/@dj.berlinbintang'
            }, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        {
            id: 3, name: 'Triple Trouble', imgSrc: '/img/guest/triple_trouble.jpg', socialLinks: {
                facebook: 'https://facebook.com',
                twitter: 'https://twitter.com',
                instagram: 'https://www.instagram.com/triotripletrouble/',
                tiktok: 'https://linkedin.com'
            }, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        {
            id: 4, name: 'Black Diary', imgSrc: '/img/guest/black_diary.jpg', socialLinks: {
                facebook: 'https://www.facebook.com/Blackdiarybali/',
                twitter: 'https://twitter.com',
                instagram: 'https://www.instagram.com/black_diary_bali/',
                tiktok: 'https://linkedin.com'
            }, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        // {
        //     id: 5, name: 'Joel Mastana', imgSrc: '/placeholder.png', socialLinks: {
        //         facebook: 'https://facebook.com',
        //         twitter: 'https://twitter.com',
        //         instagram: 'https://instagram.com',
        //         tiktok: 'https://linkedin.com'
        //     }, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        // },
    ];

    const groupedGuests = [];
    for (let i = 0; i < guests.length; i += itemsPerPage) {
        groupedGuests.push(guests.slice(i, i + itemsPerPage));
    }

    const totalPages = groupedGuests.length;

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalPages);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + totalPages) % totalPages);
    };

    return (
        <div className="">
            <div className="overflow-hidden mb-8">
                <div
                    className="flex justify-center items-center transition-transform duration-500 ease-in-out"
                    style={{ transform: `translateX(-${currentIndex * (100 / totalPages)}%)`, width: `${100 * totalPages}%` }}
                >
                    {groupedGuests.map((group, index) => (
                        <div
                            key={index}
                            className="flex justify-center items-center flex-row flex-shrink-0 gap-6"
                            style={{ width: `${100 / totalPages}%` }}
                        >
                            {group.map((guest) => (
                                <div
                                    key={guest.id}
                                    className="flex flex-col items-center flex-shrink-0 w-40 md:w-52"
                                >
                                    <img
                                        src={guest.imgSrc}
                                        alt={`Profile of ${guest.name}`}
                                        className="w-40 md:w-48 h-40 md:h-48 rounded-full object-cover mb-[-1rem]"
                                    />
                                    <div className="social-media-icons flex space-x-4 mb-4 bg-white py-2 px-3 md:px-6 rounded-full text-md md:text-xl w-full flex justify-center">
                                        {guest.socialLinks.facebook && (
                                            <a href={guest.socialLinks.facebook} target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-600">
                                                <FaFacebookF />
                                            </a>
                                        )}

                                        {guest.socialLinks.twitter && (
                                            <a href={guest.socialLinks.twitter} target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-600">
                                                <FaXTwitter />
                                            </a>
                                        )}

                                        {guest.socialLinks.instagram && (
                                            <a href={guest.socialLinks.instagram} target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-600">
                                                <FaInstagram />
                                            </a>
                                        )}

                                        {guest.socialLinks.tiktok && (
                                            <a href={guest.socialLinks.tiktok} target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-600">
                                                <FaTiktok />
                                            </a>
                                        )}

                                    </div>
                                    <p className="text-xl font-semibold">{guest.name}</p>
                                    {/* <p className="text-center md:w-2/3">{guest.description}</p> */}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <button
                onClick={prevSlide}
                className="absolute left-8 top-[3rem] md:top-1/2 transform -translate-y-1/2 p-2 bg-black text-orange rounded-full text-xl"
            >
                <FaChevronLeft />
            </button>
            <button
                onClick={nextSlide}
                className="absolute right-8 top-[3rem] md:top-1/2 transform -translate-y-1/2 p-2 bg-black text-orange rounded-full text-xl"
            >
                <FaChevronRight />
            </button>
            <div className="absolute left-1/2 transform -translate-x-1/2 flex space-x-2">
                {groupedGuests.map((_, index) => (
                    <span
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        className={`block w-4 h-4 rounded-full bg-gray-200 transition-all duration-300 hover:cursor-pointer ${currentIndex === index ? 'bg-orange opacity-100' : 'opacity-50'}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default Carousel;
