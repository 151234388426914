import React, { useState } from 'react';
import { FaFacebookF, FaInstagram, FaYoutube, FaWhatsapp, FaTwitter, FaLinkedinIn, FaTiktok } from 'react-icons/fa';
import Navbar from '../components/Navbar';
import { FaCircleCheck, FaXTwitter } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import Countdown from '../components/Countdown';
import Event from '../components/Event';
import Carousel from '../components/Carousel';
import FAQ from '../components/Faq';
import RegisterButton from '../components/RegisterButton';

const LandingPage = () => {
    const { t } = useTranslation();
    const [expandedEvent, setExpandedEvent] = useState(null);

    const handleToggle = (eventId) => {
        setExpandedEvent(expandedEvent === eventId ? null : eventId);
    };

    return (
        <div className='overflow-hidden'>
            {/* <Countdown endDate="2024-08-14T00:00:00Z" /> */}
            <Navbar />
            {/* hero */}
            <section id="home" className="relative">
                <div
                    className="h-[500px] md:h-fit flex flex-col justify-center items-center text-black pt-16 pb-[5rem] md:pt-[8rem] bg-img-white relative"
                >
                    <img
                        src="/LOGO(REAL).png"
                        alt="Logo"
                        className="absolute h-[350px] md:h-[500px] object-contain z-0 opacity-25"
                    />
                    <div className="flex flex-col w-full ml-[5rem] text-start space-y-8 z-10 relative mt-8 md:mt-0">
                        <div className="flex flex-col items-start mb-[-0.75rem] md:mb-[-1rem]">
                            <img src='/HARLEY_FEST.png' className='w-44 md:w-80' />
                            <p className="text-lg md:text-3xl my-4 md:my-8 w-44 md:w-96"><b>The 1<sup>st</sup> Harley-Davidson® official event in Indonesia.</b></p>
                            <RegisterButton className="bg-black hover:bg-orange-600 hover:text-black text-orange font-bold py-2 px-4 md:px-8 rounded-full text-md md:text-xl" label="REGISTER HERE" />
                        </div>
                        <div className="flex space-x-4 md:space-x-6 text-xl md:text-3xl">
                            {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100">
                                <FaFacebookF />
                            </a> */}
                            {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100">
                                <FaXTwitter />
                            </a> */}
                            <a href="https://instagram.com" target="_blank" rel="noreferrer" className="opacity-50 hover:opacity-100">
                                <FaWhatsapp />
                            </a>
                            <a href="https://www.instagram.com/indonesiaharleyfest?igsh=anpjOGgxb3c0N2Y2
" target="_blank" rel="noreferrer" className="opacity-50 hover:opacity-100">
                                <FaInstagram />
                            </a>
                            {/* <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100">
                                <FaYoutube />
                            </a> */}
                        </div>
                    </div>
                </div>
            </section>

            <section id="custom-kings" className="relative">
                <div className="bg-img-white">
                    <div className="grid grid-cols-3 gap-[1px] bg-black py-[1px]">
                        {/* Top Row - 3 Images */}
                        <div className="relative group">
                            <img src="/img/ck/1.jpg" alt="Image of a green Harley Davidson motorbike" className="w-full h-[5.5rem] md:h-[15rem] object-cover" />
                            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-opacity duration-300"></div>
                        </div>
                        <div className="relative group">
                            <img src="/img/ck/2.jpg" alt="Image of a black Harley Davidson motorbike" className="w-full h-[5.5rem] md:h-[15rem] object-cover" />
                            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-opacity duration-300"></div>
                        </div>
                        <div className="relative group">
                            <img src="/img/ck/3.jpg" alt="Image of a black Harley Davidson motorbike" className="w-full h-[5.5rem] md:h-[15rem] object-cover" />
                            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-opacity duration-300"></div>
                        </div>
                    </div>

                    {/* Middle Div */}
                    <div className="flex items-center justify-center py-8">
                        <img src="/img/custom-kings.png" alt="Logo of Custom Kings" className="h-16 md:h-32 object-contain mr-8" />
                        <div>
                            <p className='text-lg md:text-3xl'>
                                {t('ck1')}
                                <b>Custom Kings Asia</b>
                                <br />
                                {t('ck2')}
                                <br />
                                {t('ck3')}
                            </p>
                            <RegisterButton className="mt-4 bg-black hover:bg-orange-600 hover:text-black text-white font-bold py-2 px-4 md:px-8 rounded-full text-md md:text-xl" label="REGISTER" customKing={true} />
                        </div>
                    </div>

                    <div className="grid grid-cols-3 gap-[1px] bg-black py-[1px]">
                        {/* Bottom Row - 3 Images */}
                        <div className="relative group">
                            <img src="/img/ck/4.jpg" alt="Image of a black Harley Davidson motorbike" className="w-full h-18 md:h-[15rem] object-cover" />
                            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-opacity duration-300"></div>
                        </div>
                        <div className="relative group">
                            <img src="/img/ck/5.jpg" alt="Image of a black and red Harley Davidson motorbike" className="w-full h-18 md:h-[15rem] object-cover" />
                            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-opacity duration-300"></div>
                        </div>
                        <div className="relative group">
                            <img src="/img/ck/6.jpg" alt="Image of a black and red Harley Davidson motorbike" className="w-full h-18 md:h-[15rem] object-cover" />
                            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-opacity duration-300"></div>
                        </div>
                    </div>
                </div>
            </section>


            {/* sponsors */}
            {/* <section id="custom-kings" className="bg-white py-8 z-10 font-sc">
                <div className="container mx-auto px-4">
                    <div className="flex items-center justify-center">
                        <img src="/img/custom-kings.png" alt="Sponsor 1" className="h-12 md:h-24 object-contain" />
                    </div>
                </div>
            </section> */}

            {/* events */}
            <section id="events" className='relative'>
                <div
                    className="h-auto flex flex-col justify-center items-center text-white pt-8 md:pt-16 bg-img-black relative pb-12"
                >
                    <div className='w-3/4 md:w-[50rem]'>
                        <h1 className='mb-4'>EVENTS</h1>
                    </div>
                    <div className="container-events">
                        <div className="event-1 relative">
                            <Event
                                title="Safety Riding Competition"
                                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                isExpanded={expandedEvent === 'event-1'}
                                onToggle={() => handleToggle('event-1')}
                            />
                        </div>
                        <div className="event-2 relative">
                            <Event
                                title="Entertainment"
                                description="Ullamcorper sit amet risus nullam eget felis. Lacus vel facilisis volutpat est. Scelerisque felis imperdiet proin fermentum. Quis imperdiet massa tincidunt nunc pulvinar."
                                isExpanded={expandedEvent === 'event-2'}
                                onToggle={() => handleToggle('event-2')}
                            />
                        </div>
                        <div className="event-3 relative">
                            <Event
                                title="Performance Show"
                                description="Tempus urna et pharetra pharetra. Risus viverra adipiscing at in. Eget nunc scelerisque viverra mauris in. Leo duis ut diam quam."
                                isExpanded={expandedEvent === 'event-3'}
                                onToggle={() => handleToggle('event-3')}
                            />
                        </div>
                        <div className="event-4 relative">
                            <Event
                                title="Games"
                                description="Nec ultrices dui sapien eget mi proin sed. Gravida in fermentum et sollicitudin. Odio eu feugiat pretium nibh ipsum consequat nisl vel. At risus viverra adipiscing at. Suscipit tellus mauris a diam maecenas sed. Diam sollicitudin tempor id eu. Luctus venenatis lectus magna fringilla urna porttitor rhoncus."
                                isExpanded={expandedEvent === 'event-4'}
                                onToggle={() => handleToggle('event-4')}
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* packages */}
            {/* <section id="packages" className='relative bg-black pb-12'>
                <div className="h-auto flex flex-col justify-center items-center text-white pt-8 md:pt-16 relative">
                    <h1 className="mb-4 md:mb-8">PACKAGES & BUNDLING</h1>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 px-4">
                        <div className="px-2 py-4 md:p-6 rounded-lg text-black w-[10rem] md:w-[15rem]" style={{ backgroundColor: "#26A3C7" }}>
                            <h2 className="text-md mb-4">Basic</h2>
                            <div className="flex flex-col">
                                <span className="text-xl mb-1">IDR</span>
                                <div className="flex items-baseline">
                                    <p className="text-5xl font-bold">1000</p>
                                    <span className="text-xl ml-1">K</span>
                                </div>
                            </div>

                            <div className="my-6 space-y-2">
                                <div className="flex items-center">
                                    <FaCircleCheck className="text-black mr-2" />
                                    <span>Free Entry</span>
                                </div>
                                <div className="flex items-center">
                                    <FaCircleCheck className="text-black mr-2" />
                                    <span>Snack & Dinner</span>
                                </div>
                                <div className="flex items-center">
                                    <FaCircleCheck className="text-black mr-2" />
                                    <span>Benefit 3</span>
                                </div>
                            </div>
                            <RegisterButton className="bg-black text-white py-2 px-10 md:px-14 rounded-full hover:bg-gray-200 hover:text-black" label="Register" initialBundle="Basic" />
                        </div>
                        <div className="px-2 py-4 md:p-6 rounded-lg text-black w-[10rem] md:w-[15rem]" style={{ backgroundColor: "#00FF95" }}>
                            <h2 className="text-md mb-4">Best Deal</h2>
                            <div className="flex flex-col">
                                <span className="text-xl mb-1">IDR</span>
                                <div className="flex items-baseline">
                                    <p className="text-5xl font-bold">1500</p>
                                    <span className="text-xl ml-1">K</span>
                                </div>
                            </div>

                            <div className="my-6 space-y-2">
                                <div className="flex items-center">
                                    <FaCircleCheck className="text-black mr-2" />
                                    <span>Free Entry</span>
                                </div>
                                <div className="flex items-center">
                                    <FaCircleCheck className="text-black mr-2" />
                                    <span>Snack & Dinner</span>
                                </div>
                                <div className="flex items-center">
                                    <FaCircleCheck className="text-black mr-2" />
                                    <span>Benefit 3</span>
                                </div>
                            </div>
                            <RegisterButton className="bg-black text-white py-2 px-10 md:px-14 rounded-full hover:bg-gray-200 hover:text-black" label="Register" initialBundle="Best Deal" />
                        </div>
                        <div className="bg-orange px-2 py-4 md:p-6 rounded-lg white-shadow col-span-2 justify-self-center md:col-span-1 md:justify-self-auto w-[10rem] md:w-[15rem]">
                            <h2 className="text-md font-semibold mb-4">LIMITED</h2>
                            <div className="flex flex-col">
                                <span className="text-xl mb-1">IDR</span>
                                <div className="flex items-baseline">
                                    <p className="text-5xl font-bold">2000</p>
                                    <span className="text-xl ml-1">K</span>
                                </div>
                            </div>

                            <div className="space-y-2 my-6">
                                <div className="flex items-center">
                                    <FaCircleCheck className="text-white mr-2" />
                                    <span>Free Entry</span>
                                </div>
                                <div className="flex items-center">
                                    <FaCircleCheck className="text-white mr-2" />
                                    <span>Snack & Dinner</span>
                                </div>
                                <div className="flex items-center">
                                    <FaCircleCheck className="text-white mr-2" />
                                    <span>Benefit 3</span>
                                </div>
                            </div>
                            <RegisterButton className="bg-black text-white py-2 px-10 md:px-14 rounded-full hover:bg-gray-200 hover:text-black" label="Register" initialBundle="Limited" />
                        </div>
                    </div>
                </div>
            </section> */}

            {/* schedules */}
            <section id="schedule" className='relative bg-img-white pb-12'>
                <div className="h-auto flex flex-col items-center text-white pt-8 md:pt-16 relative md:px-12">
                    <div className='w-3/4 md:w-[50rem] mb-4'>
                        <h1 className='text-black'>SCHEDULE</h1>
                    </div>
                    <div className="grid grid-rows-3 w-3/4 items-center justify-center">
                        <div className="row flex h-[15rem] w-full md:w-[50rem]">
                            <div className="w-1/2 bg-black flex items-center justify-center">
                                <div className="text-center">
                                    <p className="text-xl text-white font-semibold tracking-widest">{t('morning')}</p>
                                    <div className="flex items-baseline justify-center">
                                        <p className="font-bold text-orange m-[-0.75rem] text-[2rem] md:text-[4rem]">09:00</p>
                                        <p className="hidden md:flex font-bold text-xl ml-4 mr-1 text-orange relative">AM</p>
                                        {/* <p className="font-bold text-3xl text-orange relative" style={{ top: "1rem" }}>08</p> */}
                                    </div>
                                    <p className="md:hidden text-gray-400 font-bold text-xl tracking-widest">AM</p>
                                </div>
                            </div>
                            <div className="flex-1 bg-transparent text-black text-md md:text-2xl items-center flex ml-4">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </div>
                        </div>
                        <div className="row flex h-[15rem] w-full md:w-[50rem]">
                            <div className="flex-1 bg-transparent text-black text-md md:text-2xl items-center text-end flex mr-4">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </div>
                            <div className="w-1/2 bg-black flex items-center justify-center">
                                <div className="text-center">
                                    <p className="text-xl text-white font-semibold tracking-widest">{t('afternoon')}</p>
                                    <div className="flex items-baseline justify-center">
                                        <p className="font-bold text-orange m-[-0.75rem] text-[2rem] md:text-[4rem]">12:00</p>
                                        <p className="hidden md:flex font-bold text-xl ml-4 mr-1 text-orange relative">PM</p>
                                    </div>
                                    <p className="md:hidden text-gray-400 font-bold text-xl tracking-widest">PM</p>
                                </div>
                            </div>

                        </div>
                        <div className="row flex h-[15rem] w-full md:w-[50rem]">
                            <div className="w-1/2 bg-black flex items-center justify-center">
                                <div className="text-center">
                                    <p className="text-xl text-white font-semibold tracking-widest">{t('evening')}</p>
                                    <div className="flex items-baseline justify-center">
                                        <p className="font-bold text-orange m-[-0.75rem] text-[2rem] md:text-[4rem]">18:00</p>
                                        <p className="hidden md:flex font-bold text-xl ml-4 mr-1 text-orange relative">PM</p>
                                    </div>
                                    <p className="md:hidden text-gray-400 font-bold text-xl tracking-widest">PM</p>
                                </div>
                            </div>
                            <div className="flex-1 bg-transparent text-black text-md md:text-2xl items-center flex ml-4">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* guests */}
            <section id="guests" className='relative bg-black pb-12'>
                <div className="h-auto flex flex-col justify-center items-center text-white pt-8 md:pt-16 relative">
                    <h1 className="mb-4 md:mb-8">GUESTS & PERFORMANCE</h1>
                    <div className='flex flex-row gap-8'>
                        <Carousel />
                    </div>
                </div>
            </section>

            {/* venue */}
            <section id="venue" className="relative bg-img-black pb-12">
                <div className="h-auto flex flex-col justify-center items-center text-white pt-8 md:pt-16 relative">
                    <div className="w-3/4 md:w-[50rem] text-end">
                        <h1 className="md:mb-8">JOIN US</h1>
                    </div>
                    <div className="w-3/4 md:w-[50rem] flex flex-col md:flex-row justify-center">
                        {/* Left Side: Date, Venue Address, and Buttons */}
                        <div className="flex flex-col w-full md:w-3/5 space-y-6 mr-10 justify-center">
                            <div className="text-right">
                                {/* <p className="text-3xl font-semibold text-orange">31 August ‘24</p> */}
                                <p className="text-xl">
                                    Bali, Indonesia
                                    {/* Peninsula Island<br />
                                    Nusa Dua, Benoa<br />
                                    South Kuta, Bandung Regency<br />
                                    Bali<br /> */}
                                </p>
                            </div>
                            <div className="flex flex-row gap-2 justify-end">
                                <a href='https://maps.app.goo.gl/Vmw9JJQTFSc1QzhG6' target="_blank" rel="noreferrer" className="border-2 border-orange-400 text-orange font-bold py-2 md:px-4 px-3 text-sm w-auto h-[2rem] flex items-center md:h-[3rem] rounded-full hover:bg-orange-400 hover:text-black">
                                    GET DIRECTIONS
                                </a>
                                <RegisterButton className="bg-orange text-black font-bold py-2 md:px-4 px-3 text-sm w-auto h-[2rem] flex items-center md:h-[3rem] rounded-full hover:bg-black hover:text-orange-600" label="REGISTER HERE" />
                            </div>
                        </div>
                        {/* Right Side: Map */}
                        <div className="mt-4 md:w-1/2">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d505146.766071563!2d114.74190903280765!3d-8.45465411869598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd141d3e8100fa1%3A0x24910fb14b24e690!2sBali!5e0!3m2!1sen!2sid!4v1722427781182!5m2!1sen!2sid"
                                className='responsive-iframe'
                                style={{ border: 0, borderRadius: '12px' }}
                                allowFullScreen="true"
                                loading="lazy"
                                title="Venue Location"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>

            {/* faq */}
            <section id="faq" className="relative bg-img-white pb-4 md:pb-12">
                <div className="h-auto flex flex-col justify-center items-center pt-8 md:pt-16 relative text-black">
                    <div className="w-3/4 md:w-[50rem] text-start mb-4 md:mb-8">
                        <h1 className="">FREQUENTLY ASKED QUESTIONS</h1>
                        <p>{t("faq1")} <b>admin@indonesiaharleyfest.com</b> {t("faq2")}</p>
                    </div>
                    <FAQ />
                </div>
            </section>

            {/* sponsors */}
            <section id="sponsors" className="bg-black py-8 z-10 font-sc">
                <div className="container mx-auto px-4">
                    <h1 className="text-white text-2xl md:text-3xl text-center mb-6">SPONSORED BY</h1>
                    <div className="grid grid-cols-4 gap-4">
                        <div className="flex items-center justify-center">
                            <img src="/placeholder.png" alt="Sponsor 1" className="h-12 md:h-24 object-contain" />
                        </div>
                        <div className="flex items-center justify-center">
                            <img src="/placeholder.png" alt="Sponsor 2" className="h-12 md:h-24 object-contain" />
                        </div>
                        <div className="flex items-center justify-center">
                            <img src="/placeholder.png" alt="Sponsor 3" className="h-12 md:h-24 object-contain" />
                        </div>
                        <div className="flex items-center justify-center">
                            <img src="/placeholder.png" alt="Sponsor 4" className="h-12 md:h-24 object-contain" />
                        </div>
                    </div>
                </div>
            </section>

            <footer className="relative bg-black py-8 text-white font-sc">
                <div className="container mx-auto flex justify-between items-start">
                    {/* Logo Section */}
                    <div className="w-1/3 flex justify-center items-center">
                        <img src="/LOGO (UPDATED).png" alt="Site Logo" className="md:w-[20rem]" />
                    </div>

                    {/* Vertical Line */}
                    <div className="flex justify-center items-center my-[25%] md:my-auto md:mr-8">
                        <div className="h-8 md:h-12 border-l-2 border-white"></div>
                    </div>

                    <div class="container-footer px-4 w-3/4 md:w-1/2">
                        {/* Site Map Section */}
                        <div className="site-map w-1/3 mr-4">
                            <h2 className="text-md md:text-xl font-bold">SITE MAP</h2>
                            <ul className='text-md font-condensed tracking-wider my-4'>
                                <li><a href="#home" className="text-gray-400 hover:text-white">Home</a></li>
                                <li><a href="#events" className="text-gray-400 hover:text-white">Events</a></li>
                                <li><a href="#schedule" className="text-gray-400 hover:text-white">Schedule</a></li>
                                <li><a href="#faq" className="text-gray-400 hover:text-white">FAQ</a></li>
                            </ul>
                            <RegisterButton className="text-lg font-condensed text-start tracking-wider text-orange hover:text-white" label="REGISTER HERE" />
                        </div>
                        {/* Socials Section */}
                        <div className="socials w-full flex flex-col mx-auto mt-8 flex justify-center items-start w-[125%] md:w-100%">
                            <h2 className="text-md md:text-xl font-bold mb-4">FOLLOW US</h2>
                            <div className="flex space-x-6 text-xl md:text-3xl">
                                {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100">
                                    <FaFacebookF />
                                </a>
                                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100">
                                    <FaXTwitter />
                                </a> */}
                                <a href="https://instagram.com" target="_blank" rel="noreferrer" className="opacity-50 hover:opacity-100">
                                    <FaWhatsapp />
                                </a>
                                <a href="https://www.instagram.com/indonesiaharleyfest?igsh=anpjOGgxb3c0N2Y2
" target="_blank" rel="noreferrer" className="opacity-50 hover:opacity-100">
                                    <FaInstagram />
                                </a>
                                {/* <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100">
                                    <FaYoutube />
                                </a> */}
                            </div>
                        </div>
                        {/* Newsletter Section */}
                        <div className="newsletter w-full flex flex-col mx-auto mt-8 flex items-start w-100%">
                            <h2 className="text-md md:text-xl font-bold mb-4">NEWSLETTER</h2>
                            <div className="w-auto p-1 rounded-full flex items-center border border-orange-500 w-[100%]">
                                <input
                                    type="email"
                                    placeholder="Enter your email to subscribe to our newsletter"
                                    className="w-full p-1 rounded-full text-white bg-black focus:outline-none"
                                />
                                <button className="subscribe-button bg-orange-500 rounded-full hover:bg-orange-600 text-black font-bold text-sm md:text-md py-2 px-1 md:px-8">
                                    SUBSCRIBE
                                </button>
                            </div>
                        </div>
                        {/* Location Section */}
                        <div className="location w-1/3">
                            <h2 className="text-md md:text-xl font-bold">LOCATION</h2>
                            <p className="text-md font-condensed tracking-wider my-4">
                                Bali, Indonesia
                                {/* Peninsula Island<br />
                                Nusa Dua, Benoa<br />
                                South Kuta, Bandung Regency<br />
                                Bali<br />
                                80363 */}
                            </p>
                            <a href='https://maps.app.goo.gl/Vmw9JJQTFSc1QzhG6' target="_blank" rel="noreferrer" className="text-lg font-condensed tracking-wider"><u>SEE ON MAP</u></a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center mt-12">
                    {/* <div className='flex flex-row'>
                        <img className='w-[10rem]' src='/img/brands/inchcape.png' />
                        <img className='w-[10rem]' src='/img/brands/jlmauto.png' />
                    </div> */}
                    <div>
                        <span className="opacity-50 font-condensed tracking-wider">
                            Copyright©2024 • INDONESIAHARLEYFEST.COM
                        </span>
                    </div>

                </div>
            </footer>
        </div>
    );
};

export default LandingPage;
